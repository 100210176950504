// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-blog-js": () => import("/opt/build/repo/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("/opt/build/repo/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-tag-js": () => import("/opt/build/repo/src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-studies-evoke-index-js": () => import("/opt/build/repo/src/pages/case-studies/evoke/index.js" /* webpackChunkName: "component---src-pages-case-studies-evoke-index-js" */),
  "component---src-pages-case-studies-index-js": () => import("/opt/build/repo/src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-case-studies-ldc-index-js": () => import("/opt/build/repo/src/pages/case-studies/ldc/index.js" /* webpackChunkName: "component---src-pages-case-studies-ldc-index-js" */),
  "component---src-pages-case-studies-learningforward-index-js": () => import("/opt/build/repo/src/pages/case-studies/learningforward/index.js" /* webpackChunkName: "component---src-pages-case-studies-learningforward-index-js" */),
  "component---src-pages-case-studies-pdredesign-index-js": () => import("/opt/build/repo/src/pages/case-studies/pdredesign/index.js" /* webpackChunkName: "component---src-pages-case-studies-pdredesign-index-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

